import { Component, AfterViewInit } from '@angular/core';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['not-found.component.scss']

})

export class NotFoundComponent implements AfterViewInit {

  public date;

  constructor(private datePipe: DatePipe) {
    this.date = this.datePipe.transform(this.date, 'yyyy');
  }


  ngAfterViewInit() {
    $(function () {
      $('.preloader').fadeOut();
    });
  }
}
